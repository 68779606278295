import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import Footer from 'components/Footer'
import PortfolioHeader from 'components/PortfolioHeader'

import BackgroundSection from 'components/BackgroundSection'

import {
  MoeInvoice,
  MoeComments,
  MoeShare,
  MoeDelete,
  MoePen,
} from 'components/Icons'

const MoeAssist = () => {
  const {
    moeLogo,
    campaign,
    projectsListView,
    projectsGridView,
    campaignDetail,
    featureSet,
    pbjLive,
    spiritune,
    creatively,
    home,
  } = useStaticQuery(
    graphql`
      query {
        moeLogo: file(relativePath: { eq: "moe-assist/moe-logo.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        campaign: file(relativePath: { eq: "moe-assist/campaign.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        projectsListView: file(
          relativePath: { eq: "moe-assist/moe-projects-list.png" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        projectsGridView: file(
          relativePath: { eq: "moe-assist/moe-projects-grid.png" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        campaignDetail: file(
          relativePath: { eq: "moe-assist/campaign-detail.png" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        featureSet: file(
          relativePath: { eq: "moe-assist/moe-feature-set.png" }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        home: file(relativePath: { eq: "moe-assist/home.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        spiritune: file(relativePath: { eq: "spiritune-cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        pbjLive: file(relativePath: { eq: "pbj-live-cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        creatively: file(relativePath: { eq: "creatively-footer-cover.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  )

  return (
    <Layout>
      <SEO />
      <PortfolioHeader
        heading="The first project management and payments tool for influencers"
        image={home}
        singleImage
        containerClassName="moe-assist"
      />
      <div className="portfolio-wrapper">
        <div className="container --pb0">
          <section className="portfolio-section-header">
            <div className="portfolio-section-header-text">
              <h4 className="portfolio-section-header-heading">
                Take control of your business.
              </h4>
              <p className="portfolio-section-header-description">
                MOE is the first project management tool that legitimizes the
                influencer workflow by providing the tools necessary to build
                your community and partnerships.
              </p>
            </div>
          </section>
          <section className="creatively__app">
            <div data-sal="zoom-in">
              <BackgroundSection
                image={moeLogo}
                className="creatively__app-grid --moe-logo"
              />
            </div>
            <div data-sal="slide-left">
              <BackgroundSection
                image={campaign}
                className="creatively__app-grid --campaign"
              />
            </div>
          </section>
          <section className="portfolio-icons --moe-assist">
            <MoeShare data-sal="fade" data-sal-delay="400" />
            <MoeDelete data-sal="fade" data-sal-delay="300" />
            <MoeComments data-sal="fade" data-sal-delay="500" />
            <MoePen data-sal="fade" data-sal-delay="200" />
            <MoeInvoice data-sal="fade" data-sal-delay="100" />
          </section>
          <section data-sal="slide-up">
            <BackgroundSection
              image={featureSet}
              className="creatively__desktop-preview --feature-set --with-text"
            >
              <div className="desktop-preview-content">
                <h4 className="desktop-preview-heading">
                  Tools that help you scale.
                </h4>
                <p className="desktop-preview-description">
                  MOE helps you work smarter, get paid faster, and collaborate
                  with brands better.
                </p>
              </div>
            </BackgroundSection>
          </section>
          <section className="portfolio-section-header">
            <div className="portfolio-section-header-text">
              <h4 className="portfolio-section-header-heading --full">
                Built for the influencer, by an influencer.
              </h4>
              <p className="portfolio-section-header-description">
                Founder Danielle Bernstein knows how important it is to build
                the foundation for a scalable business, as she learned firsthand
                when building WeWoreWhat. Inspired for this product by her own
                real-life Moe, she wanted to ensure that the features empowered
                influencers to grow.
              </p>
            </div>
          </section>
          <section data-sal="slide-up">
            <BackgroundSection
              image={campaignDetail}
              className="creatively__desktop-preview --campaign-detail"
            />
          </section>
          <section className="creatively__app">
            <div data-sal="zoom-in">
              <BackgroundSection
                image={projectsListView}
                className="creatively__app-grid --projects-list-view"
              />
            </div>
            <div data-sal="slide-left">
              <BackgroundSection
                image={projectsGridView}
                className="creatively__app-grid --projects-grid-view"
              />
            </div>
          </section>
          <section className="spiritune-app-navigation-footer">
            <h4 className="spiritune-app-navigation-footer-main-heading">
              See what else we’re building
            </h4>
            <div className="spiritune-app-navigation-footer-grid">
              <Link to="/portfolio/spiritune">
                <BackgroundSection
                  image={spiritune}
                  className="spiritune-app-navigation-footer-spiritune"
                  data-sal="zoom-out"
                >
                  <h4 className="spiritune-app-navigation-footer-heading">
                    Spiritune
                  </h4>
                  <h4 className="spiritune-app-navigation-footer-description">
                    Managing emotional well-being through music
                  </h4>
                </BackgroundSection>
              </Link>
              <Link to="/portfolio/pbj-live">
                <BackgroundSection
                  image={pbjLive}
                  className="spiritune-app-navigation-footer-pbj-live"
                  data-sal="zoom-out"
                >
                  <h4 className="spiritune-app-navigation-footer-heading">
                    PBJ Live
                  </h4>
                  <h4 className="spiritune-app-navigation-footer-description">
                    Your content - wherever, whenever
                  </h4>
                </BackgroundSection>
              </Link>
              <Link to="/portfolio/creatively">
                <BackgroundSection
                  image={creatively}
                  className="spiritune-app-navigation-footer-creatively"
                >
                  <h4 className="spiritune-app-navigation-footer-heading">
                    Creatively
                  </h4>
                  <h4 className="spiritune-app-navigation-footer-description">
                    Connecting the creative community
                  </h4>
                </BackgroundSection>
              </Link>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default MoeAssist
